<template>
  <div id="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <h5>Quiero Registrar Mi Marca.com ....</h5>
          <p class="small">
            Copyright © 2020 Todos los derechos reservados.
            <br />
            <!-- <a
              name="terminos"
              id="terminos"
              href=""
              data-toggle="modal"
              data-target=".bd-example-modal-terminos"
              >Términos y condiciones</a
            > -->
            <router-link
              :to="{ name: 'TerminosCondicionesPage' }" 
              v-scroll-to="'#terminos'">
              Términos y condiciones
            </router-link>
            |
            <router-link
              :to="{ name: 'AvisoPrivacidadPage' }" 
              v-scroll-to="'#aviso-privacidad'">
              Aviso de privacidad
            </router-link>
            <br />
            Desarrollado por
            <a href="https://www.movemini.com/" target="blank">Movemini</a>
          </p>
        </div>
        <div class="col-lg-5">
          <div class="row">
            <div class="col text-right small">
              <router-link
                class="footer-nav"
                v-for="opcion in opcionesMenu"
                :key="opcion.texto"
                :to="'/' + opcion.href"
                v-scroll-to="opcion.href"
              >
                {{ opcion.texto.toUpperCase() }}
              </router-link>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col text-right">
              <a
                class="ml-4"
                :href="redSocial.href"
                target="blank"
                v-for="redSocial in redesSociales"
                :key="redSocial.redSocial"
              >
                <i :class="redSocial.icono"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainFooter',
  props: ['opcionesMenu', 'redesSociales'],

}
</script>
