<template>
  <div class="home">
    <main-menu :opciones-menu="opcionesMenu"></main-menu>
    <router-view></router-view>
    <main-footer :opciones-menu="opcionesMenu" :redes-sociales="redesSociales">
    </main-footer>
  </div>
</template>

<script>
import MainMenu from '@/components/MainMenu.vue'
import MainFooter from '@/components/MainFooter.vue'
export default {
  name: 'Home',
  components: {
    MainMenu,
    MainFooter,
  },
  data() {
    return {
      opcionesMenu: [
        { texto: 'Inicio', href: '#home' },
        { texto: 'Servicios', href: '#servicios' },
        { texto: 'Propiedad Intelectual', href: '#propiedad-intelectual' },
        { texto: 'Paquetes', href: '#paquetes' },
        { texto: 'Sobre nosotros', href: '#sobre-nosotros' },
        { texto:'Equipo',href:'#equipo'},
        { texto: 'Preguntas Frecuentes', href: '#preguntas-frecuentes' },
        { texto: 'Contacto', href: '#contacto' },
        //Button develop
        //{ texto: 'Dictamen Gratuito', href: '/DictamenGratuito' },
      ],
      redesSociales: [
        {
          redSocial: 'Facebook',
          icono: 'fab fa-facebook fa-2x',
          href: 'https://www.facebook.com/QRM-101306698367269/',
        },
      ],
    }
  },

}

</script>
