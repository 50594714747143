<template>
  <section>
    <!-- HERO -->
    <div id="home" class="parallax">
      <div class="container">
        <h1 class="text-center"> QUIERO REGISTRAR <span class="h1-color">MI MARCA.COM </span></h1>
      <br> <h4><p  class="text-center display color-d" style="font-size:130%;font-weight: 700;">
           Haz que tu marca trabaje para ti, ¡Regístrala! ®
        </p></h4>
       <br> <h2><p class="text-center display">
        Honradez, Responsabilidad y Puntualidad
        </p></h2>
        <!--
        <router-link
          class="btn btn-leer"
          to="/solicitud"
          @click.native="scroll"
          >"Registra tu marca ahora"</router-link
        >
        -->
        <a href="./QRM/solicitud" class="btn btn-leer">"Registra tu marca ahora"</a>
        <!-- <a href="#servicios" class="btn btn-leer">Leer más </a> -->
      </div>
    </div>

    <!-- SERVICIOS -->
    <div id="servicios">
      <div class="container">
        <h2 class="subtitle">SERVICIOS</h2>
        <hr class="divider" />
        <div class="row align-items-center bgr-c">
          <div class="col-md-4 text-center my-3">
            <i class="icons fa-6x far fa-check-circle"></i>
          </div>
          <div class="col-md-8 text-center text-md-left my-3">
            <div class= "text_line" >
              <h4 class= "line">Dictamen de Viabilidad</h4>
            </div>
            <p class="text-justify">
              Llena el formato con tus datos, realiza tu pago en línea y
              nosotros te enviaremos tu dictamen con la respuesta a tu
              solicitud, así como los pasos a seguir.
            </p>
          </div>
        </div>
        <div class="divide-cl"><br></div>
        <div
          class="row align-items-center flex-column-reverse flex-md-row bgr-c"
        >
          <div class="col-md-8 text-center text-md-right my-3">
            <div class= "text_line" >
              <h4 class= "line">Registro de Marca</h4>
            </div>
            <p class="text-justify">
              Es el activo más importante de tu empresa, te ayuda a diferenciar
              productos y servicios de tus competidores. Su registro te dará el
              derecho al uso exclusivo en el territorio nacional.
            </p>
          </div>
          <div class="col-md-4 my-3 text-center">
            <i class="icons far fa-6x fa-registered"></i>
          </div>
        </div>
        <div class="divide-cl"><br></div>
        <div class="row align-items-center  bgr-c">
          <div class="col-md-4 my-3 text-center">
            <i class="icons far fa-6x fa-copyright"></i>
          </div>
          <div class="col-md-8 my-3 text-center text-md-left">
            <div class= "text_line" >
              <h4 class= "line">Registro de Aviso Comercial (Slogan)</h4>
            </div>
            <p class="text-justify">
              Es la frase que distinguirá tus productos o servicios de otros
              existentes en el mercado.
            </p>
          </div>
        </div>
        <div class="divide-cl"><br></div>

          <div
            class="row justify-content-center flex-column-reverse flex-md-row bgr-c"
          >
            <div class="col-md-8 text-center text-md-right my-3">
              <div class= "text_line" >
                <h4 class= "line">Vigilancia de Marca</h4>
              </div>
              <p class="text-justify">
                Este servicio sirve para monitorear tu Marca y/o Aviso comercial una vez obtenido su registro,
                 con la finalidad de mantener la exclusividad y evitar que la competencia registre una marca
                 igual o semejante a la tuya.
              </p>
            </div>
            <div class="col-md-4 text-center my-3">
              <i class="icons fas fa-6x fa-shield-alt"></i>
            </div>
          </div>
          <div class="divide-cl"></div>


      </div>
    </div>

    <!-- PROPIEDAD INTELECTUAL -->
    <div id="propiedad-intelectual">
      <div class="container">
        <h2 class="subtitle">Propiedad intelectual</h2>
        <hr class="divider" />
        <div class="row align-items-center">
          <div class="col-md-12">
            <img
              class="img-fluid img-galeria"
              :src="getImage('galeria/intelectual.png')"
              alt="infografía sobre la propiedad intelectual"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- ASESORAMIENTO -->
    <div id="asesoramiento" class="parallax">
      <div class="container">
        <!-- <h3>Te asesoramos de inicio a fin en el proceso de registro de tu marca de manera simple y accesible</h3> -->
        <h3>
          Registramos tu marca o aviso comercial (slogan) con unos sencillos
          datos
        </h3>
        <br />
        <ol class="text-justify" type="1">
          <!-- <router-link class="h1-color" :to="'/'" v-scroll-to="opcion.href">{{ opcion.texto.toUpperCase() }}</router-link> -->
          <li>
            Elige tu
            <router-link class="h1-color" to="/" v-scroll-to="'#paquetes'"
              >paquete</router-link
            >.
          </li>
          <li>Realiza el pago en línea.</li>
          <li>
            Estaremos al pendiente de cada etapa de tu trámite solicitado, con
            tus claves de acceso podrás revisar el avance.
          </li>
        </ol>
        <p>
          Una vez que el IMPI otorgue el registro, te enviaremos el título de
          tu marca hasta la puerta de tu casa u oficina.
          <br />
          <strong>Envío gratis a toda la república mexicana.</strong>
        </p>
        <!-- <a href="#paquetes" class="btn btn-asesoramiento">SOLICITAR PAQUETES</a> -->
        <!--
        <router-link class="btn btn-asesoramiento"  @click.native="scroll" to="/solicitud"
          >SOLICITAR MI PAQUETE</router-link
        >
        -->
        <a href="./QRM/solicitud" class="btn btn-asesoramiento">SOLICITAR MI PAQUETE</a>
        <router-link
          class="btn btn-asesoramiento"
          to="/"
          v-scroll-to="'#contacto'"
          >CONTACTO Y ASESORÍA PERSONALIZADA</router-link
        >
        <!-- <a href="#contacto" class="btn btn-asesoramiento"
          >CONTACTO Y ASESORÍA PERSONALIZADA</a
        > -->
      </div>
    </div>

    <!-- PAQUETES -->
    <div id="paquetes" class="">
      <div class="container">
        <h2 class="subtitle">Paquetes</h2>
        <hr class="divider" />

        <div class="columnas-3">
          <div class="col-lg-4 card-paquete mt-4 mb-4 tpaquete">
            <h5 class="paquete-titulo">Dictamen</h5>
            <div class="paquete-body">
              <p class="font-weight-bold">Dictamen de viabilidad</p>
              <p>
                    Examinaremos la disponibilidad para obtener el registro de tu
                    marca o aviso comercial (Slogan), evitando que sea similar a
                    otras ya existentes.
              </p>
              <hr class="divider" />
              <p class="font-weight-bold">Incluye:</p>
              <ul>
                <li>Informe de búsqueda fonética y/o figurativa.</li>
                <li>Asesoría personalizada.</li>
                <li>
                  Recibe vía correo electrónico, el examen de viabilidad con tu
                  tabla de probabilidades de registro.
                </li>
              </ul>
            </div>
            <div class="paquete-footer text-center">
              <p class="precio">costo <span>$500.00 MXN</span></p>
            </div>
          </div>
          <div class="col-lg-4 card-paquete mt-4 mb-4 tpaquete">
            <h5 class="paquete-titulo">Registro Nacional</h5>
            <div class="paquete-body">
              <p class="card-text font-weight-bold">
                Registro de marca o aviso comercial (slogan) Nacional
              </p>
              <p class="card-text">
                    Presentamos tu solicitud de marca o aviso comercial (Slogan)
                    ante el IMPI, te enviamos el acuse respectivo y le damos
                    seguimiento a tu trámite.
              </p>
              <hr class="divider" />
              <p class="card-text font-weight-bold">Incluye:</p>
              <ul>
                <li>Dictamen de viabilidad.</li>
                <li>Presentación de solicitud nacional.</li>
                <li>Asesoría personalizada.</li>
                <li>Seguimiento a tu trámite.</li>
                <li>Reporte de avances.</li>
                <li>
                  Entrega de certificado digital y a domicilio dentro de la República Mexicana sin costo adicional.
                </li>
                <li>Entrega de stickers con el logo de tu marca.</li>
                <li>Publicación en nuestras redes sociales.</li>
                <li>Recordatorios para declaración de uso y renovación.</li>
                <li>Entrega de título promocionando tu marca en nuestro canal ”EnDirecto Radio TV".
                 <img
                    class="img-paquete"
                    :src="getImage('paquetes/image004.png')"
                    alt=""
                  />
                </li>
              </ul>
            </div>
            <div class="paquete-footer text-center">
              <p class="precio">costo <span>$8,000 MXN</span></p>
            </div>
          </div>
          <div class="col-lg-4 card-paquete mt-4 mb-4 tpaquete">
            <h5 class="paquete-titulo ">Registro Internacional</h5>

            <div class="paquete-body">
              <p class="font-weight-bold">
                    Registro de marca o aviso comercial (slogan) Internacional
              </p>
              <p class="">
                    Presentamos correctamente la solicitud de marca o aviso
                    comercial (Slogan) con protección en territorio internacional,
                    te enviamos el acuse respectivo y le damos seguimiento a tu
                    trámite.
              </p>
              <hr class="divider" />
              <p class="font-weight-bold">Incluye:</p>
              <ul>
                <li>Dictamen de viabilidad.</li>
                <li>
                  Presentación de solicitud internacional de acuerdo al
                  Protocolo de Madrid.
                </li>
                <li>Asesoría online personalizada.</li>
                <li>Seguimiento a tu trámite.</li>
                <li>Protección de tu marca a nivel internacional.</li>
                <li>Traducción de oficios de la autoridad.</li>
                <li>Reportes de avances (inglés/español).</li>
                <li>Publicación en nuestras redes sociales.</li>
                <li>Recordatorios para declaración de uso y renovación.</li>
                <li>
                  Entrega de certificado digital promocionando tu marca en nuestro canal ”EnDirecto Radio TV".
                 <img
                    class="img-paquete img-paquete-d"
                    :src="getImage('paquetes/image004.png')"
                    alt=""
                  />
                </li>
              </ul>
            </div>
            <div class="paquete-footer text-center">
              <p class="precio">costo <span>$1,000 USD + TASAS</span></p>
            </div>
          </div>


        </div>
        <br />
        <div class="row">
          <div class="col text-center">
            <!--
            <router-link class="btn btn-paquete" to="/solicitud" @click.native="scroll"
              >Solicitar mi paquete</router-link
            >
            -->
            <a href="./QRM/solicitud" class="btn btn-paquete">Solicitar mi paquete</a>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col">
            <small class="text-muted">
              <p>En todos los casos incluímos:</p>
              <ul>
                <li>Presentamos tu solicitud en menos de 48 horas.</li>
                <li>
                  Solicita tu logo con tan solo $3,000 (tres mil pesos 00/100 MXN)
                  adicionales (se entregan tres diseños novedosos y creativos).
                </li>
                <li>
                  Te enviamos tu título de registro de marca o aviso comercial
                  (slogan) totalmente gratis. Aplica únicamente dentro de la
                  República Mexicana.
                </li>
                <li>Los importes incluyen el pago de derechos al IMPI.</li>
                <li>Los importes no incluyen IVA.</li>
              </ul>
              <p>
                El otorgamiento del registro de marca o aviso comercial
                (slogan), es facultad potestativa de la autoridad y no
                responsabilidad de “QRM, Quiero Registrar mi Marca®".
              </p>
            </small>
          </div>
        </div>
      </div>
    </div>

    <!-- SOBRE NOSOTROS -->
    <div id="sobre-nosotros">
      <div class="container">
        <h2 class="subtitle">SOBRE NOSOTROS</h2>
        <hr class="divider" />
        <img
              class="img-nosotros"
              :src="getImage('sobre-nosotros/despacho01.png')"
              alt=""
        />
        <p class="f-opacity ">
              En <i><span class="colored"> QRM – Quiero Registrar mi Marca, ®</span></i>
              encontraras abogados especializados en Propiedad Intelectual,
             <i> <span class="colored"> Haz que tu marca trabaje para ti, ¡Regístrala! ®,</span></i>
              Nuestra pasión es hacer que tu creatividad tenga un impacto real.</p>

        <img
              class="img-nosotros"
              :src="getImage('sobre-nosotros/despacho03.png')"
              alt=""
        />
        <div class="row align-items-center my-5 col-dos">
          <div class="col-dos-t text-justify">
            <div class="col-dos">
              <div class="col-dos-m">
                <h5 class="subtitle line">MISIÓN</h5>
                <p>
                  Registrar los productos y servicios en forma honesta,
                  puntual y responsable para obtener la protección de los diferentes tipos
                  de marcas de nuestros clientes.
                </p>
              </div>
              <div class="col-dos-m">
                <h5 class="subtitle line">VISIÓN</h5>
                <p>
                  Fortalecer la creatividad de nuestros clientes
                  dando certeza jurídica de sus acciones, acompañándolas en el crecimiento
                  de sus proyectos.
                </p>
              </div>
            </div>

          </div>
          <div class="col-dos-t">
            <h5 class="subtitle line">NUESTROS VALORES</h5>
            <p>
                <b>Honradez:</b> Virtud que va de la mano con la verdad,
                aún en situaciones difíciles.  <br /><br /><b>Responsabilidad:</b> Dar cumplimiento
                con eficacia a lo que nos hemos comprometido.  <br /><br /><b>Puntualidad:</b> Diligencia en
                las actividades que se nos encomiendan y ser merecedores de la confianza de nuestros clientes.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- EQUIPO -->
    <div id="equipo">
      <h2 class="subtitle">EQUIPO</h2>
      <hr class="divider" />
      <div class="presentacion">
        <div class="row align-items-center no-gutters">
          <div class="col-lg-6 my-3 my-lg-0 black">
            <img
              class="img-equipo"
              :src="getImage('equipo/2000_5e33d3b4e420a.png')"
              alt="Foto Lic. Gabriela Carreño Calva"
            />
          </div>
          <div class="col-lg-6 my-3 my-lg-0">
            <div class="info-equipo">
              <h4>Mtra. Gabriela Carreño Calva</h4>
              <hr class="sub-divider" />
              <h6 class="rol">Socia Fundadora</h6>
              <p class="text-justify">
                Socia Directora en Grupo HerCar, CEO de Hernández Carreño
                Abogados (firma especializada en derecho corporativo y propiedad
                intelectual), Directora Ejecutiva de Consultorio Jurídico de
                Servicios Integrales (despacho de abogados con más de 21 años de
                experiencia, ranqueado como uno de los mejores despachos de
                abogados de México), Conferencista especializada en Propiedad Intelectual,
                 Creadora de la marca <span class="colored">QRM – Quiero Registrar mi Marca ®</span> y
                <span class="colored">Haz que tu marca trabaje para ti, ¡Regístrala! ®</span>.
              </p>
            </div>
          </div>
        </div>
        <div
          class="row align-items-center no-gutters flex-column-reverse flex-lg-row"
        >
          <div class="col-lg-6 my-3 my-lg-0">
            <div class="info-equipo">
              <h4>Dr. Walter Omar Hernández Ortiz</h4>
              <hr class="sub-divider" />
              <h6 class="rol">Socio Fundador</h6>
              <p class="text-justify">
                Presidente de Grupo HerCar, Socio Director de Hernández
                Carreño Abogados (firma especializada en derecho corporativo y
                propiedad intelectual), Director General de Consultorio Jurídico
                de Servicios Integrales (despacho de abogados con más de 21 años
                de experiencia, ranqueado como uno de los mejores despachos de
                abogados de México). Creador de la marca <span class="colored">Toma Chocolate y Paga lo que Debes ®</span> y
                <span class="colored">Usted Venda, Nosotros Cobramos ®</span>.
              </p>
            </div>
          </div>
          <div class="col-lg-6 my-3 my-lg-0 black">
            <img
              class="img-equipo"
              :src="getImage('equipo/2000_5e33d3cd3eb25.png')"
              alt="Foto Lic. Gabriela Carreño Calva"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- MARCAS -->
    <marcas></marcas>

    <!--PREGUNTAS FRECUENTES-->
    <preguntas-frecuentes></preguntas-frecuentes>

    <!-- CONTACTO -->
    <contacto></contacto>
  </section>
</template>

<script>
import Marcas from '@/components/Marcas.vue';
//import PreguntasFrecuentes from '@/components/PreguntasFrecuentes.vue'
import PreguntasFrecuentes from "../components/PreguntasFrecuentes";
import Contacto from '@/components/Contacto.vue'
import {contadorUsuarios} from "@/assets/js/ContadorUsuarios.js";
export default {
  name: 'LandingPage',
  components: { Marcas, PreguntasFrecuentes, Contacto, },
  methods: {
    getImage(img) {
      return require(`@/assets/img/${img}`)
    },
    scroll: function () {
      window.scroll({
      top: 0,
      behavior: 'smooth'
     });
     contadorUsuarios.actualizarDatos("SOLICITUD PASO 1");
    }
  },
}

</script>

<style scoped></style>
